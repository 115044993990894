<template>
  <div class="page-header-container">
    <div class="page-header-body">

      <title-view></title-view>

      <search-view v-if="false"></search-view>

      <nav-view></nav-view>

     <el-badge style="margin: auto" v-if="getXczx">
       <img src="~@/assets/images/jcb.png" @click="goto('/data/index')" height="34" width="144" style="margin-top: 3px" alt="决策指挥"/>
     </el-badge>


      <el-dropdown
        style=" margin-left: auto;"
        @command="onCommand"
      >
        <div class="user-box">
          <el-avatar
            :src="userAvatar"
            :size="30"
          ></el-avatar>
          <span class="name">{{ username }}</span>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            divided
            command="userCenter"
          >
            <div style="width: 100px;">个人中心</div>
          </el-dropdown-item>
          <el-dropdown-item
            divided
            command="userSetting"
          >
            <div style="width: 100px;">账号设置</div>
          </el-dropdown-item>
          <el-dropdown-item
            divided
            command="logout"
          >
            <div style="width: 100px;">退出</div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

    </div>
  </div>
</template>

<script>
import NavView from './header-nav'
import TitleView from './header-title'
import SearchView from './header-search'
import Image from "@/components/upload/image.vue";
import { getLocal, setLocal } from '@/store/storage'
export default {
  components: {
    Image,
    NavView,
    TitleView,
    SearchView,
  },
  data() {
    return {}
  },
  computed: {
    username() {
      return this.$store.state.user.userInfo.name
    },
    userAvatar() {
      return this.$store.state.user.userInfo.avatar
    },
    getXczx(){
      return ![4, 6,7].includes(getLocal('user_info').isShowXczx)
    }
  },
  methods: {
    goto(url){
      this.$router.push({ path: url })
    },
    logout() {
      this.$confirm('确定要退出系统吗', '提示', { type: 'warning' })
        .then(() => {
          this.$store.dispatch('user/LOGOUT', 'login')
        })
        .catch(() => {})
    },
    onCommand(command) {
      // console.log('3333', command)
      switch (command) {
        case 'userCenter':
          this.$router.push({ path: '/user-center' })
          break
        case 'userSetting':
          this.$router.push({ path: '/user-setting' })
          break
        case 'logout':
          this.logout()
          break
        default:
          console.log('error command')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.page-header-container {
  position: relative;
  width: 100%;
  height: 60px;
  min-height: 60px;

  .page-header-body {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 20px;
    background: #fff;
    box-shadow: 0 3px 8px 0 rgba(141, 153, 175, 0.08);
  }

  .user-box {
    display: flex;
    align-items: center;
    cursor: pointer;

    .avatar {
      width: 32px;
      height: 32px;
      margin-left: 20px;
      border-radius: 50%;
    }

    .name {
      margin-right: 20px;
      margin-left: 10px;
      font-size: 16px;
      color: #747e8f;
    }
  }
}
</style>
