export default [
  {
    path: '/',
    name: 'index',
    component: 'home/index',
    title: '系统首页',
    type: 'page',
    menu: true,
  },
  {
    path: 'task/backlog',
    name: 'task/backlog',
    component: 'task/backlog',
    title: '任务马上办',
    menu: true,
    type: 'page',
  },

  {
    path: 'task',
    name: 'task',
    component: 'task/index',
    title: '任务发布',
    menu: true,
    type: 'page',
  },
  {
    path: '/task/create',
    name: 'task/create',
    component: 'task/detail',
    title: '创建任务',
    type: 'page',
  },
  {
    path: '/task/update',
    name: 'task/update',
    component: 'task/detail',
    title: '更新任务',
    type: 'page',
  },
  {
    path: 'task/info',
    name: 'task/info',
    component: 'task/info',
    title: '任务详情',
    type: 'page',
  },
  {
    name: 'userWork',
    title: '我的工作',
    menu: true,
    type: 'menu',
  },
  {
    path: 'manager-user/schedule',
    name: 'managerUser/schedule',
    component: 'manager-user/schedule/index',
    parent: 'userWork',
    title: '我的日程',
    type: 'page',
    menu: true,
  },
  // {
  //   path: 'manager-user/list',
  //   name: 'managerUser/list',
  //   component: 'manager-user/list/index',
  //   parent: 'userWork',
  //   title: '我的工作清单',
  //   type: 'page',
  //   menu: true,
  // },
  {
    path: 'manager/feedback-user',
    name: 'manager/feedbackUser',
    component: 'manager/feedback-user',
    title: '个人任务反馈',
    type: 'page',
  },
  {
    name: 'deptWork',
    title: '部门工作',
    type: 'menu',
    menu: true,
    meta: {},
  },
  {
    path: 'dept',
    name: 'dept',
    component: 'dept/index',
    parent: 'deptWork',
    title: '部门中心',
    type: 'page',
    menu: true,
  },
  {
    path: 'news',
    name: 'news',
    component: 'news/index',
    parent: 'deptWork',
    title: '部门成绩单',
    menu: true,
    type: 'page',
  },
  {
    path: 'works',
    name: 'works',
    component: 'news/works',
    parent: 'deptWork',
    title: '部门工作',
    menu: true,
    type: 'page',
  },
  {
    path: 'news/worksCreate',
    name: 'news/worksCreate',
    component: 'news/worksDetail',
    parent: 'deptWork',
    title: '创建部门工作',
  
    type: 'page',
  },
  {
    path: 'news/worksUpdate',
    name: 'news/worksUpdate',
    component: 'news/worksDetail',
    parent: 'deptWork',
    title: '更新部门工作',
 
    type: 'page',
  },
  {
    path: 'news/worksInfo',
    name: 'news/worksInfo',
    component: 'news/worksInfo',
    parent: 'deptWork',
    title: '部门工作详情',
   
    type: 'page',
  },
  {
    path: 'news/create',
    name: 'news/create',
    component: 'news/detail',
    title: '创建成绩单',
    type: 'page',
  },
  {
    path: 'news/update',
    name: 'news/update',
    component: 'news/detail',
    title: '更新成绩单',
    type: 'page',
  },
  {
    path: 'news/info',
    name: 'news/info',
    component: 'news/info',
    title: '成绩单详情',
    type: 'page',
  },
  // {
  //   path: 'manager/schedule',
  //   name: 'manager/schedule',
  //   component: 'manager/schedule/index',
  //   parent: 'deptWork',
  //   title: '部门日程',
  //   type: 'page',
  //   menu: true,
  // },
  {
    path: 'manager/list',
    name: 'manager/list',
    component: 'manager/list/index',
    parent: 'deptWork',
    // title: '部门清单',
    title: '近期工作',
    type: 'page',
    menu: true,
  },
  {
    path: '/manager/feedback',
    name: 'manager/feedback',
    component: 'manager/feedback',
    parent: 'deptWork',
    title: '部门反馈统计',
    type: 'page',
    menu: true,
  },
  {
    path: '/manager/attention',
    name: 'manager/attention',
    component: 'manager/list',
    parent: 'deptWork',
    type: 'page',
    title: '工作计划、总结',
    menu: true,
    meta: {},
  },
  {
    path: '/manager/flow',
    name: 'manager/flow',
    component: 'manager/list',
    parent: 'deptWork',
    title: '部门流程',
    type: 'page',
    menu: true,
    meta: {},
  },
  {
    path: '/manager/system',
    name: 'manager/system',
    component: 'manager/list',
    parent: 'deptWork',
    title: '部门制度',
    type: 'page',
    menu: true,
    meta: {},
  },
  {
    name: 'report',
    title: '领导工作',
    menu: true,
    type: 'menu',
    meta: {},
  },
  {
    path: 'report/dashboard',
    name: 'report/dashboard',
    component: 'report/dashboard/index',
    parent: 'report',
    title: '领导日报查看',
    menu: true,
    type: 'page',
  },
  {
    path: 'report/audit',
    name: 'report/audit',
    component: 'report/audit/index',
    parent: 'report',
    title: '领导日报审核',
    menu: true,
    type: 'page',
    meta: {
      navId: [process.env.VUE_APP_SUPER_USER_ID, process.env.VUE_APP_REPORT_AUDIT_USER_ID, '388'],
    },
  },
  {
    path: 'report/list/index',
    name: 'report/list',
    component: 'report/list/index',
    parent: 'report',
    title: '领导日报上报',
    menu: true,
    type: 'page',
    meta: {},
  },
  {
    name: 'permission',
    title: '权限管理',
    type: 'menu',
    menu: true,
    meta: {},
  },
  {
    path: '/project',
    name: 'project',
    component: 'project/dashboard',
    parent: 'permission',
    title: '项目监管',
    type: 'page',
    menu: true,
    meta: {
      navId: process.env.VUE_APP_SUPER_USER_ID,
    },
  },
  {
    path: '/project/info/:id',
    name: 'project/info',
    component: 'project/info',
    title: '项目详情',
    type: 'page',
    meta: {
      navId: process.env.VUE_APP_SUPER_USER_ID,
    },
  },
  {
    path: '/user',
    name: 'user',
    component: 'user/index',
    parent: 'permission',
    title: '用户管理',
    type: 'page',
    menu: true,
    meta: {
      navId: process.env.VUE_APP_SUPER_USER_ID,
    },
  },
  {
    path: '/user/attendance',
    name: 'user/attendance',
    component: 'user/attendance',
    parent: 'permission',
    title: '考勤管理',
    menu: true,
    type: 'page',
    meta: {
      navId: [process.env.VUE_APP_SUPER_USER_ID, '201'],
    },
  },
  {
    path: '/feedback',
    name: 'feedback',
    component: 'feedback/index',
    parent: 'permission',
    title: '意见反馈',
    menu: true,
    type: 'page',
    meta: {
      navId: process.env.VUE_APP_SUPER_USER_ID,
    },
  },
  {
    path: '/feedback/info',
    name: 'feedback/info',
    component: 'feedback/info',
    title: '意见详情',
    type: 'page',
    meta: {
      navId: process.env.VUE_APP_SUPER_USER_ID,
    },
  },
  {
    path: 'user-center',
    name: 'userCenter',
    component: 'admin/user-center/index',
    title: '个人中心',
    type: 'page',
  },
  {
    path: 'user-setting',
    name: 'userSetting',
    component: 'admin/user-setting/index',
    title: '账号设置',
    type: 'page',
  },
  {
    path: '/admin/post',
    name: 'admin/post',
    component: 'admin/post/index',
    title: '职务管理',
    parent: 'permission',
    type: 'page',
    menu: true,
    meta: {
      navId: process.env.VUE_APP_SUPER_USER_ID,
    },
  },
  {
    name: 'special',
    title: '政治监督',
    type: 'menu',
    menu: true,
    meta: {
      isShowXczx:[6,4,7]
    },
  },
  {
    parent: 'special',
    name: 'special/list/my',
    path: 'special/list/my',
    component: 'special/list-create/index',
    title: '我创建的',
    type: 'page',
    menu: true,
    meta: {},
  },
  {
    parent: 'special',
    name: 'special/list/attention',
    path: 'special/list/attention',
    component: 'special/list-create/index',
    title: '我关注的',
    type: 'page',
    menu: true,
    meta: {},
  },
  {
    parent: 'special',
    name: 'special/list/receive',
    path: 'special/list/receive',
    component: 'special/list/index',
    title: '部门收到的',
    type: 'page',
    menu: true,
    meta: {},
  },
  {
    parent: 'special',
    name: 'special/list/create',
    path: 'special/list/create',
    component: 'special/list-create/index',
    title: '部门创建的',
    type: 'page',
    menu: true,
    meta: {},
  },
  {
    name: 'special/detail',
    path: 'special/detail',
    component: 'special/detail/index',
    title: '政治监督详情',
    type: 'page',
    menu: false,
    meta: {},
  },
  {
    name: 'farming/list',
    path: 'farming/list/index',
    component: 'farming/list/index',
    title: '三农专题',
    type: 'page',
    menu: false,
    meta: {},
  },
  {
    name: 'farming/detail',
    path: 'farming/detail/index',
    component: 'farming/detail/index',
    title: '三农专题-重要论述',
    type: 'page',
    menu: false,
    meta: {},
  },
  {
    name: 'farming/action',
    path: 'farming/action/index',
    component: 'farming/action/index',
    title: '三农专题-讲话详情',
    type: 'page',
    menu: false,
    meta: {},
  },
  {
    path: 'manager/data',
    name: 'manager/data',
    component: 'manager/data',
    title: '工作基础数据库',
    type: 'page',
    menu: true,
    meta: {},
  },
  {
    path: 'proposal/data',
    name: 'proposal/data',
    component: 'proposal/data',
    title: '建议',
    type: 'page',
    menu: true,
    meta: {},
  },
  {
    path: 'notice/list',
    name: 'notice/backlistlog',
    component: 'notice/list',
    title: '通知公告',
    menu: true,
    type: 'page',
  },
  {
    path: 'notice/info',
    name: 'notice/info',
    component: 'notice/info',
    title: '通知公告详情',
    type: 'page',
  },

  // {
  //   path: 'task_file/backlog',
  //   name: 'task_file/backlog',
  //   component: 'task_file/backlog',
  //   title: '收文(非涉密外网)',
  //   menu: true,
  //   type: 'page',
  // },
  // {
  //   path: 'task_file',
  //   name: 'task_file',
  //   component: 'task_file/index',
  //   title: '发文(非涉密外网)',
  //   menu: true,
  //   type: 'page',
  // },
  // {
  //   path: '/task_file/create',
  //   name: 'task_file/create',
  //   component: 'task_file/detail',
  //   title: '发文传阅(非涉密外网)',
  //   type: 'page',
  // },
  // {
  //   path: '/task_file/update',
  //   name: 'task_file/update',
  //   component: 'task_file/detail',
  //   title: '更新文件',
  //   type: 'page',
  // },
  // {
  //   path: 'task_file/info',
  //   name: 'task_file/info',
  //   component: 'task_file/info',
  //   title: '文件详情',
  //   type: 'page',
  // },

]
